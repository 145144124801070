import axiosClient from '@/http'
import { AUTOPAY_SELECTABLE_STATUSES } from '@/common/modules/constants'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'
import * as Cookies from 'js-cookie'
import http from '@/http'
import { formatDateStringFromSeconds } from '@/common/modules/formatters'
import { getCardLogo } from '@/common/modules/cc'
import { mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export const companyServiceMixin = {
  data() {
    return {
      interaction: null,
      loaded: false,
      rows: [],
      selectedPayableId: null,
      selectedService: null,
      thisUid: this._uid,
    }
  },
  mixins: [makeToastMixin],
  computed: {
    ...mapGetters('paymentMethods', ['cards', 'automatedClearingHouses']),
    isNotAdminUser() {
      return sessionStorage.getItem('admin-logged-in') !== 'true' &&
        Cookies.get('admin') !== 'logged_in_as_client'
    },
  },
  methods: {
    addAutopayPayableIdToServices(bundledServices, autopayPayableId) {
      return bundledServices.map(service => ({ ...service, autopay_payable_id: autopayPayableId }))
    },
    async addPaymentMethodHandler(payableId) {
      // Immediately add new payment method as Autopay payable for selected service
      await this.processAutopayUpdates(payableId)
      this.$bvModal.hide('payment-method-modal')
      this.reloadServicesTable()
    },
    autopayPayableSelectable(status) {
      return Object.values(AUTOPAY_SELECTABLE_STATUSES).includes(status)
    },
    autopayPayableSelectHandler(payableId, row) {
      this.rowAutopayChanged = row

      if (payableId === 'ADD') {
        this.selectedService = row.item
        this.presentAddPayment()
      } else {
        this.saveAndPresentUpdateAutoPayModal(payableId, row)
      }
    },
    canOptIntoPaperBilling(item) {
      item.data = item.data || {}
      item.data.paper_billing = item.data.paper_billing || false
      return item.autopay_payable_id !== null
    },
    determineSelectable(records) {
      let check_for_selection = []
      try {
        records.forEach((item) => {
          check_for_selection.push(this.isSelectable(item))
        })
      } catch { return }
      return check_for_selection.includes(true)
    },
    durationText(item) {
      const endDate = this.formatDate(item.stop)
      if (item.status === 'trial-active') {
        return `Converts to Active ${endDate}`
      }

      return item.autopay_payable_id ? `Renews ${endDate}` : `Ends on ${endDate}`
    },
    failToast(message='Payment method could not be updated, please try again later or contact us for assistance.') {
      this.$bvToast.toast(message, {
        title: 'Error',
        variant: 'danger',
        solid: true,
        NoAutoHide: true,
      })
    },
    formatDate(date) {
      return !date || date === 'Invalid Date' ? '--' : formatDateStringFromSeconds(date)
    },
    forwardingAddressLine1(item) {
      if (item.product.subcategory === 'free_limited_use' && item.mail_forwarding_address) {
        return item.mail_forwarding_address.line1 + ' ' + (item.mail_forwarding_address.line2 ? item.mail_forwarding_address.line2 : '')
      }

      return item.mail_forwarding_address.line1 + ' #' + item.mail_forwarding_items.suite_number
    },
    getPayableInfo(service) {
      const card = this.cards.find(card => card.id === service.autopay_payable_id)
      const ach = this.automatedClearingHouses.find(ach => ach.id === service.autopay_payable_id)

      if(card)
        return { image: getCardLogo(card.brand), number: `**** ${card.last4}` }
      if(ach)
        return { image: '/images/automatedClearingHouses/ach.svg', number: `**${ach.bank_account_number.slice(-2)}` }
    },

    handlePaperBillingOptIn(item) {
      this.loading = true
      http.post(`client/services/set_data`, {
        id: item.id,
        service: { data: item.data },
      })
        .catch(() => this.errorToast('Error', 'Error setting paper billing'))
        .finally(() => this.loading = false)
    },
    isFreeLimitedUse(item) {
      return (item.mail_forwarding_address && item.mail_forwarding_items
          && item.mail_forwarding_items.active === false
          && item.mail_forwarding_items.service_type === 'free_limited_use') ||
        (item.mail_forwarding_address && !item.mail_forwarding_items
          && item.product.subcategory === 'free_limited_use')
    },
    isNotPendingCancellation(item) {
      return item?.status !== 'cancel-requested' && this.status !== 'inactive'
    },
    isRenewable(item) {
      return this.isSelectable(item) || this.isSelectableAlt(item) || this.isDomainService(item)
    },
    isSelectable(item) {
      return this.isNotPendingCancellation(item) && !this.isVIPBundle(item) && !this.isVIPBundleItem(item) && !this.isDomainService(item)
    },
    isSelectableAlt(item) {
      return this.isNotPendingCancellation(item) && this.isVIPBundle(item)
    },
    isTrialActive(item) {
      return item.status === 'trial-active'
    },
    isVIPBundle(item) {
      return item?.product?.name === 'VIP Subscription'
    },
    isVIPBundleItem(item) {
      return item.subscription_id && !this.isVIPBundle(item)
    },
    isDomainService(item) {
      return item?.type === 'business-domain'
    },
    jurisdiction(item) {
      return !this.isVIPBundle(item) && item.jurisdiction?.state_province_region || '--'
    },
    logAutopaySetupInteraction(service_ids) {
      const interaction = {
          name: 'services-page',
          action: 'manage-autopay-clicked',
          service_ids,
        }
      createOrFindClientInteractionLog({
        category: 'autopay-interaction',
        subCategory: 'manage-autopay',
        interaction,
        incompleteLogFromToday: true,
      }).catch() // suppress any api errors
    },
    mailForwardingAddress(item) {
      const lines = [item.mail_forwarding_address && this.company.name || null]
      if(!this.shouldDisplayAddressWithSuite(item) && !this.isFreeLimitedUse(item)) return lines

      lines.push(this.forwardingAddressLine1(item))
      lines.push(`${item.mail_forwarding_address.city},
                  ${item.mail_forwarding_address.state_province_region},
                  ${item.mail_forwarding_address.zip_postal_code},
                  ${item.mail_forwarding_address.country}`
      )

      return lines
    },
    async manageAutopay(serviceIds) {
      this.logAutopaySetupInteraction(serviceIds)
      await this.$router.push({
        name: 'manageAutopay',
        query: { serviceIds: serviceIds.join(',') },
      })
    },
    onLoad(rows) {
      this.rows = rows
    },
    phoneServiceNumber(item) {
      return ['virtual-phone', 'call-forwarding'].includes(item.type)
        && item.virtual_phones?.number || null
    },
    presentAddPayment() {
      const ref = `payment-method-modal-${this.thisUid}`
      this.$refs[ref].$refs['payment-method-modal'].show()
    },
    presentRegisteredAgentDetails(item) {
      if (this.isNotAdminUser) {
        createOrFindClientInteractionLog({
          category: 'registered-agents',
          subCategory: 'registered-agent-details-modal',
          interaction: {
            action: 'visit',
            name: 'open-modal',
          },
          incompleteLogFromToday: true,
        }).then((interaction) => {
          this.interaction = interaction
        })
      }

      this.raItem = item;
      const ref = `registered-agent-details-modal-${this.thisUid}`
      this.$refs[ref].$refs['registered-agent-details-modal'].show()
    },
    async processAutopayUpdates(payableId = this.selectedPayableId) {
      const service = this.selectedService
      const servicesToUpdate = ('services' in service) ? service.services : [service]
      const servicesWithAutopayId = this.addAutopayPayableIdToServices(servicesToUpdate, payableId)
      await this.submitMultipleAutopayUpdates(servicesWithAutopayId)
    },
    registeredAgentAddress(item) {
      const lines = [item.registered_agent_name]
      const raAddress = item.registered_agent_address
      if(!raAddress) return lines

      const street = raAddress.line2 ? `${raAddress.line1} ${raAddress.line2}` : raAddress.line1
      const county = item.registered_agent_address?.county
      const region = `${raAddress.city}, ${raAddress.state_province_region}, ${raAddress.zip_postal_code}, ${raAddress.country}`

      lines.push(street, county, region)
      return lines
    },
    reloadServicesTable() {
      this.$refs.servicesTable.reload()
    },
    showRegisteredAgentModalLink(service) {
      return service.product &&
        service.registered_agent_address &&
        service.registered_agent_name
    },
    shouldDisplayAddressWithSuite(item) {
      return item.mail_forwarding_address
        && item.mail_forwarding_items
        && item.mail_forwarding_items.active
    },
    async submitMultipleAutopayUpdates(services) {
      let setAutopayIdRequests = []
      try {
        for (const element of services) {
          setAutopayIdRequests.push(
            await axiosClient.post(`client/companies/${this.companyId}/services/${element.id}/set_autopay_id`, element)
          )
        }
        const responses = await Promise.all(setAutopayIdRequests)
        // For first failed request, show its failure message and return.
        // Multiple requests are always on Bundles.
        for (const res of responses) {
          if (res?.data?.success === false) {
            const message = res?.data?.result?.message?.message || null
            message ? this.failToast(message) : this.failToast()
            return
          }
        }
        this.successToast()
        // Catch other (i.e. local network) errors
      } catch (_e) {
        this.failToast()
      }
      this.reloadServicesTable()
    },
    async saveAndPresentUpdateAutoPayModal(payableId, row) {
      this.selectedPayableId = payableId
      this.selectedService = row.item

      if (payableId === null) {
        const ref = `update-autopay-modal-${this.thisUid}`
        this.$refs[ref].$refs['updatePaymentMethodModal'].show()
      } else {
        await this.processAutopayUpdates()
      }
    },
    successToast() {
      const message = 'Payment method updated successfully.'
      this.$bvToast.toast(message,{
        title: 'Success',
        variant: 'success',
        solid: true,
        NoAutoHide: true,
      })
    },
    showUnassignedAutopay() {
      const noAutopayableCount = this.activeAccountServices.filter(service => service.autopay_payable_id === null).length

      if (noAutopayableCount >= 1) {
        this.$bvToast.toast(`There are service(s) that can be setup on autopay.`, {
          title: 'Setup Autopay Today',
          variant: 'info',
          solid: true,
          autoHideDelay: 3000,
        });
      }
    },
  },
}
